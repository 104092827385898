import React, { FunctionComponent, PropsWithChildren, useCallback, useEffect } from 'react'
import { SagaHelper } from '../../../../store/sagaHelper'

type Props = {
    title?: string
    subtitle?: string
}

export const OriginationModelWrapper: FunctionComponent<Props> = (props: PropsWithChildren<Props>) => {
    useEffect(() => {
        SagaHelper.run(['notification', 'listen']).finally()

        function handleKeyDown(event) {
            const charCode = String.fromCharCode(event.which).toLowerCase()
            if ((event.ctrlKey || event.metaKey) && charCode === 's') {
                // console.log('CTRL+S Pressed')
            } else if ((event.ctrlKey || event.metaKey) && charCode === 'c') {
                // console.log('CTRL+C Pressed')
            } else if ((event.ctrlKey || event.metaKey) && charCode === 'v') {
                //console.log('CTRL+V Pressed')
            } else if ((event.ctrlKey || event.metaKey) && charCode === 'z') {
                //console.log('CTRL+Z Pressed')
            }
        }
        //document.addEventListener('keydown', handleKeyDown)
    }, [])

    return (
        <>
            <div className={'origination-model-wrapper'}>
                <div className="linksupport" style={{ display: 'none' }}>
                    <a href="https://seecares.com/support" target="_blank">
                        <div className="support">
                            <div className="supportImg">
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
                                        stroke="#97A2B4"
                                        strokeWidth="1.5"
                                        strokeMiterlimit="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M7.99982 11.8182C10.1085 11.8182 11.818 10.1088 11.818 8.00007C11.818 5.89134 10.1085 4.18188 7.99982 4.18188C5.8911 4.18188 4.18164 5.89134 4.18164 8.00007C4.18164 10.1088 5.8911 11.8182 7.99982 11.8182Z"
                                        stroke="#97A2B4"
                                        strokeWidth="1.5"
                                        strokeMiterlimit="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M4.78018 5.95108L2.09473 4.23926"
                                        stroke="#97A2B4"
                                        strokeWidth="1.5"
                                        strokeMiterlimit="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M13.9052 11.7609L11.2197 10.0491"
                                        stroke="#97A2B4"
                                        strokeWidth="1.5"
                                        strokeMiterlimit="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M4.78018 10.0491L2.09473 11.7609"
                                        stroke="#97A2B4"
                                        strokeWidth="1.5"
                                        strokeMiterlimit="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M13.9052 4.23926L11.2197 5.95108"
                                        stroke="#97A2B4"
                                        strokeWidth="1.5"
                                        strokeMiterlimit="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>
                            <div className="supportLabel">Support</div>
                        </div>
                    </a>
                </div>
                <div className="logo">
                    <svg width="120" height="16" viewBox="0 0 120 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.03047 15.7181C9.35038 15.7181 11.5566 13.6466 11.5566 10.9984C11.5566 7.9445 9.72859 6.91941 6.19856 6.42822C3.59307 6.0438 3.08878 5.42448 3.08878 4.18582C3.08878 3.01123 3.97128 2.17834 5.67326 2.17834C7.39625 2.17834 8.29977 2.86174 8.57293 4.46345H11.1364C10.8422 1.53766 8.90912 0.0427246 5.67326 0.0427246C2.54246 0.0427246 0.441254 1.92207 0.441254 4.42074C0.441254 7.28246 2.14323 8.39298 5.7363 8.88417C8.1737 9.28994 8.8671 9.82384 8.8671 11.2333C8.8671 12.6429 7.69042 13.5825 6.03047 13.5825C3.48801 13.5825 2.87866 12.3012 2.68955 10.7635H0C0.210121 13.6466 1.93311 15.7181 6.03047 15.7181Z"
                            fill="#AAB1BD"
                        />
                        <path
                            d="M51.4797 15.7181C55.0518 15.7181 57.6152 13.8601 58.0565 10.1869H55.3249C55.0307 12.3439 53.5599 13.4117 51.4797 13.4117C48.7061 13.4117 47.0882 11.3828 47.0882 7.94449V7.77364C47.0882 4.35666 48.8112 2.26375 51.4587 2.26375C53.6229 2.26375 54.7366 3.3102 55.0518 5.33904H57.8884C57.5522 1.7512 54.8837 0 51.4377 0C47.2563 0 44.1465 3.20342 44.1465 7.795V7.96585C44.1465 12.6642 46.773 15.7181 51.4797 15.7181Z"
                            fill="#AAB1BD"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M64.9062 11.7245L63.7295 15.5045H61.103L66.1249 0.234863H69.781L74.7399 15.5045H71.8402L70.6425 11.7245H64.9062ZM65.5576 9.65293H69.9911L67.7849 2.56268L65.5576 9.65293Z"
                            fill="#AAB1BD"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M81.926 9.33259V15.5045H79.1313V0.234863H84.1742C87.4731 0.234863 89.7845 1.60166 89.7845 4.71966V4.80508C89.7845 7.19697 88.3136 8.41427 86.4436 8.90546L90.667 15.5045H87.6412L83.712 9.33259H81.926ZM81.926 7.30375H84.1742C86.0443 7.30375 87.0319 6.49222 87.0319 4.86915V4.78372C87.0319 3.03252 85.9603 2.43455 84.1742 2.43455H81.926V7.30375Z"
                            fill="#AAB1BD"
                        />
                        <path
                            d="M114.474 15.7181C117.794 15.7181 120 13.6466 120 10.9984C120 7.9445 118.172 6.91941 114.642 6.42822C112.036 6.0438 111.532 5.42448 111.532 4.18582C111.532 3.01123 112.415 2.17834 114.117 2.17834C115.84 2.17834 116.743 2.86174 117.016 4.46345H119.58C119.286 1.53766 117.352 0.0427246 114.117 0.0427246C110.986 0.0427246 108.885 1.92207 108.885 4.42074C108.885 7.28246 110.587 8.39298 114.18 8.88417C116.617 9.28994 117.31 9.82384 117.31 11.2333C117.31 12.6429 116.134 13.5825 114.474 13.5825C111.931 13.5825 111.322 12.3012 111.133 10.7635H108.443C108.653 13.6466 110.376 15.7181 114.474 15.7181Z"
                            fill="#AAB1BD"
                        />
                        <rect x="15.8105" y="0.60498" width="10.2617" height="2.84024" fill="#AAB1BD" />
                        <rect x="15.8105" y="6.75879" width="10.2617" height="2.84024" fill="#AAB1BD" />
                        <rect x="15.8105" y="12.9126" width="10.2617" height="2.84024" fill="#AAB1BD" />
                        <rect x="30.9585" y="0.60498" width="10.2617" height="2.84024" fill="#AAB1BD" />
                        <rect x="30.9585" y="6.75879" width="10.2617" height="2.84024" fill="#AAB1BD" />
                        <rect x="30.9585" y="12.9126" width="10.2617" height="2.84024" fill="#AAB1BD" />
                        <rect x="94.4834" y="0.60498" width="10.2617" height="2.84024" fill="#AAB1BD" />
                        <rect x="94.4834" y="6.75879" width="10.2617" height="2.84024" fill="#AAB1BD" />
                        <rect x="94.4834" y="12.9126" width="10.2617" height="2.84024" fill="#AAB1BD" />
                    </svg>
                </div>
                <div style={{ flex: 1, width: '100%', display: 'flex', flexDirection: 'column' }}>
                    <div className={'title'}> {props?.title}</div>
                    <div className={'subtitle'}> {props?.subtitle}</div>
                    <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', flex: 1 }}>
                        {props.children}
                    </div>
                </div>
                <div id={'right-dock-placeholder'} />
                <div id={'right-dock'} />
                <div id={'bottom-dock'} />
            </div>

            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                :global() {
                    @media print {
                        @page {
                            size: landscape;
                        }
                        body {
                            min-width: 3000px;
                            width: 3000px;
                        }
                        .origination-model-wrapper {
                            width: 100% !important;
                            background-color: white !important;
                            zoom: 70%;
                        }
                    }
                }
                .logo {
                    position: fixed;
                    right: 100px;
                    top: 47px;
                }
                .linksupport {
                    position: fixed;
                    right: 250px;
                }
                .support {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 10px 12px;

                    position: static;
                    width: 95px;
                    height: 36px;
                    left: 0px;
                    top: 0px;

                    border-radius: 4px;

                    /* Inside Auto Layout */

                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    margin: 8px 0px;
                }
                .supportImg {
                    position: static;
                    width: 16px;
                    left: 12px;
                    top: 10px;

                    /* Inside Auto Layout */

                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    margin: 0px 4px;
                }
                .supportLabel {
                    position: static;
                    width: 51px;
                    height: 16px;
                    left: 32px;
                    top: 10px;

                    font-family: Inter;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 16px;
                    /* identical to box height */

                    text-align: center;

                    /* n400 */

                    color: #97a2b4;

                    /* Inside Auto Layout */

                    flex: none;
                    order: 1;
                    flex-grow: 0;
                    margin: 0px 4px;
                }
                .origination-model-wrapper {
                    background-color: #f2f2f2;
                    display: flex;
                    flex-direction: row;
                    min-height: 100vh;
                    color: black;
                    padding: 35px 45px 80px;
                    font-family: 'Inter', sans-serif;
                    .title {
                        font-size: 24px;
                        font-weight: 500;
                        color: #161c26;
                        margin-bottom: 15px;
                    }

                    .subtitle {
                        font-size: 15px;
                        color: #687893;
                        margin-bottom: 25px;
                    }

                    #right-dock {
                        position: fixed;
                        height: 100vh;
                        top: 0;
                        right: 0;
                        z-index: 900;
                    }
                    #bottom-dock {
                        position: fixed;
                        bottom: 78px;
                        left: 0;
                        z-index: 1001;
                        width: 100%;
                    }
                }
            `}</style>
        </>
    )
}
