import React from 'react'

type Props = {
    handleClose: () => void
    handleConfirm: () => void
    message?: string
    confirmBtnLabel?: string
    refuseBtnLabel?: string
}

export const SaveDialog = ({ handleClose, handleConfirm, message, confirmBtnLabel, refuseBtnLabel }: Props) => {
    return (
        <div className={'dialog-layout'}>
            <div className="dialog-box" onClick={(e) => e.stopPropagation()}>
                <h2 className="dialog-title">{message || 'Do you want to save changes?'}</h2>

                <div className="dialog-buttons">
                    <button className="btn-blue btn btn-primary ghost" onClick={handleClose}>
                        {refuseBtnLabel || 'No'}
                    </button>
                    <button className="btn-blue btn btn-primary regular" onClick={handleConfirm}>
                        {confirmBtnLabel || 'Yes'}
                    </button>
                </div>
            </div>

            {/*language=scss*/}
            <style jsx global>{`
                @import './src/scss/colors.scss';

                .dialog-layout {
                    position: fixed;
                    width: 100vw;
                    height: 100vh;
                    background: rgba(0, 0, 0, 0.5);
                    backdrop-filter: blur(14px);
                    z-index: 10000;
                    left: 0;
                    top: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .dialog-box {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        background: $white;
                        width: 450px;
                        border: 1px solid $border;
                        border-radius: 4px;
                        padding: 40px;
                        .dialog-title {
                            color: $black;
                            font-size: 14px;
                            font-weight: 400;
                            margin-bottom: 30px;
                        }
                    }

                    .dialog-buttons {
                        display: flex;
                        gap: 30px;
                        button {
                            font-size: 13px;
                            box-shadow: none;
                            &.ghost {
                                color: $blue;
                                border: 1px solid $blue;
                                background: $white;
                                font-weight: 400;
                            }
                            &.regular {
                                font-weight: 500;
                            }
                        }
                    }
                }
            `}</style>
        </div>
    )
}
