import React, { useCallback, useState, useMemo, useRef, useEffect } from 'react'
import arrowIcon from './arrow-icon.svg'
import { CheckboxItemForSelect } from './CheckboxItemForSelect'
import { ReduxHelper } from '../../../../../../../../../store'

type Props = {
    data: Array<{ label: string; value: string; readOnly?: boolean }>
    defaultSelectedColumns: any[]
    reduxPath?: any[]
}

export const SelectBox = (props: Props) => {
    const { data, defaultSelectedColumns } = props
    // console.log('defaultSelectedColumns', defaultSelectedColumns)
    const reduxPath = props.reduxPath || ['lenderDashboard', 'lenderCashFlowTab', 'columnsSelected']
    const [expanded, setExpanded] = useState(false)
    const [checkedItems, setCheckedItems] = useState(
        defaultSelectedColumns
            ? defaultSelectedColumns.filter((itm) => itm !== 'underwriting')
            : ['underwriting', 't12'],
    )
    const selectRef = useRef(null)

    const items = useMemo(() => {
        return data.map((item, ind) => {
            return {
                checked: checkedItems.includes(item.value),
                value: item.value,
                label: item.label,
                readOnly: item.readOnly,
            }
        })
    }, [data, checkedItems])

    const sortArray = useCallback((arr) => {
        const valueToNumber = (v: string) => {
            if (v.match(/history\d+/)) {
                return +v.replace('history', '') + 1000
            }
            if (v.match(/t\d+/)) {
                return +v.replace('t', '') + 2000
            }
            if (v.toLowerCase().indexOf('proforma') > -1) {
                return +v.replace(/\D/g, '') + 3000
            }
        }
        arr.sort(function (a, b) {
            return valueToNumber(a) < valueToNumber(b) ? -1 : 1
        })
        return arr
    }, [])

    const changeCheckedItems = useCallback(
        (ind, checked) => {
            if (checked) {
                const checkedList = sortArray([...checkedItems, items[ind].value])
                setCheckedItems(checkedList)
                ReduxHelper.updateIn(reduxPath, (cols: string[]) => {
                    for (const item of data) {
                        const ind = cols.indexOf(item.value)
                        if (ind > -1) cols.splice(ind, 1)
                    }
                    return [...checkedList, ...cols]
                })
            } else {
                checkedItems.splice(checkedItems.indexOf(items[ind].value), 1)
                const checkedList = sortArray([...checkedItems])
                setCheckedItems(checkedList)

                ReduxHelper.updateIn(reduxPath, (cols: string[]) => {
                    for (const item of data) {
                        const ind = cols.indexOf(item.value)
                        if (ind > -1) cols.splice(ind, 1)
                    }
                    return [...checkedList, ...cols]
                })
            }
        },
        [checkedItems],
    )

    useEffect(() => {
        function handleClickOutside(event) {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setExpanded(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])
    return (
        <div className={'select-box'} ref={selectRef}>
            <div className={'selected-items'}>
                {checkedItems.map((v, ind) => {
                    const item = data.find((d) => d.value == v)
                    if (ind <= 1)
                        return (
                            <span key={ind}>
                                {ind === 1 && ',    '}
                                {item?.label}
                            </span>
                        )
                })}
                {checkedItems.length >= 3 && (
                    <div className={'counter'}>
                        <span>+{checkedItems.length - 2}</span>
                    </div>
                )}
            </div>
            <div className="arrow-icon" onClick={() => setExpanded(!expanded)}>
                <img src={arrowIcon} />
            </div>
            <div className={'select-box-content'}>
                {items.map((item, ind) => {
                    return (
                        <CheckboxItemForSelect
                            changeCheckedItems={changeCheckedItems}
                            name={item.label}
                            checked={item.checked}
                            key={ind}
                            index={ind}
                            readOnly={item.readOnly}
                        />
                    )
                })}
                <div className={'buttons'}>
                    <button className={'btn btn-primary ghost'} onClick={() => setExpanded(false)}>
                        Cancel
                    </button>
                    <button className={'btn btn-primary regular'} onClick={() => setExpanded(false)}>
                        Apply
                    </button>
                </div>
            </div>

            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .select-box {
                    background: $white;
                    border: 1px solid $border;
                    border-radius: 3px;
                    min-width: 260px;
                    padding: 10px 12px;
                    min-height: 40px;
                    width: auto;
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .selected-items {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        line-height: 0;
                        flex: 1;
                        span {
                            display: block;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 14px;
                            color: $black;
                            text-transform: capitalize;
                        }
                        .counter {
                            display: flex;
                            justify-content: flex-end;
                            flex: 1;
                            span {
                                background: #f5f6f9;
                                border-radius: 2px;
                                color: #687893;
                                padding: 1px 4px;
                                margin: 0 5px;
                                font-size: 13px;
                                line-height: 16px;
                                font-weight: 400;
                                min-width: 25px;
                            }
                        }
                    }
                    .arrow-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transform: ${expanded ? 'scaleY(-1)' : 'scaleY(1)'};
                        cursor: pointer;
                        user-select: none;
                        img {
                            margin-bottom: 0;
                        }
                    }
                    .select-box-content {
                        display: ${expanded ? 'block' : 'none'};
                        position: absolute;
                        width: 100%;
                        left: 0;
                        border: 1px solid $border;
                        border-radius: 3px;
                        background: $white;
                        top: calc(100% + 4px);
                        box-shadow: 0px 10px 20px rgba(58, 61, 66, 0.16);
                        padding: 20px;
                        z-index: 1000;
                        p {
                            color: $black;
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                        .buttons {
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            .btn {
                                font-weight: 500;
                                font-size: 13px;
                                line-height: 14px;
                                padding: 10px 12px;
                            }
                            .ghost {
                                color: #4485ff;
                                border: 1px solid #4485ff;
                                background: #fff;
                                margin-right: 4px;
                            }
                        }
                    }
                }
            `}</style>
        </div>
    )
}
