import React, { CSSProperties } from 'react'
import { Spinner } from '../../../controls/Spinner'

type Props = {
    className?: string
    title?: string
    style?: CSSProperties
    contentStyle?: CSSProperties
    showProgress?: boolean
}

export function WidgetWrapper(props: React.PropsWithChildren<Props>) {
    return (
        <>
            <div className={`widget-container ` + (props.className || '')} style={props.style}>
                {props.title && <div className={'widget-title'}>{props.title}</div>}
                {props.showProgress ? (
                    <div className={'content-loading'}>
                        <Spinner />
                    </div>
                ) : (
                    <div className={'widget-body'} style={props.contentStyle}>
                        {props.children}
                    </div>
                )}
            </div>
            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    .widget-container {
                        background-color: $white;
                        border: 1px solid $border;
                        border-radius: 3px;
                        position: relative;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: stretch;
                        .content-loading {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-grow: 1;
                        }
                    }
                    .widget-title {
                        font-size: 15px;
                        font-weight: 500;
                        color: $black;
                        border-bottom: 1px solid $border;
                        padding: 10px 20px;
                        span {
                            font-weight: 400;
                            color: $dark-gray;
                        }
                    }
                    .widget-body {
                        padding: 20px;
                    }
                `}
            </style>
        </>
    )
}
