import React from 'react'
import numeral from 'numeral'

type Symbol = 'dollar' | 'percent' | 'none' | 'units'

type Props = {
    title: string
    value: number
    className?: string
    symbol?: Symbol
    trend?: boolean
}

export function KpiCard(props: Props) {
    const classes = props.className?.split(' ')
    return (
        <div className={`card card-${props.value < 0 ? 'red' : 'green'} ${props.className}`}>
            <div className={'title'}>{props.title}</div>
            {(!classes || !classes.includes('multiFamilyInsights')) && (
                <div className={'value'}>
                    {props.value < 0 || props.className === 'submarketInsights' ? '' : '+'}
                    {props.value.toFixed(2)}%{' '}
                    {props.className === 'submarketInsights' && (
                        <>
                            {props.value > 0 ? (
                                <i className="material-icons arrow-icon" style={{ fontSize: 18, color: '#44E183' }}>
                                    arrow_upward
                                </i>
                            ) : (
                                <i className="material-icons arrow-icon" style={{ fontSize: 18, color: '#F14B44' }}>
                                    arrow_downward
                                </i>
                            )}
                        </>
                    )}
                </div>
            )}
            {!!classes && classes.includes('multiFamilyInsights') && (
                <div className={'value'}>
                    {props.symbol === 'dollar' && <>{'$' + numeral(props.value).format('0,0')}</>}
                    {props.symbol === 'percent' && <>{numeral(props.value).format('0.[00]%')}</>}
                    {props.symbol === 'none' && <>{numeral(props.value).format('0,0')}</>}
                    {props.symbol === 'units' && <>{numeral(props.value).format('0,0')} units</>}

                    {props.trend === true && (
                        <i className="material-icons arrow-icon" style={{ fontSize: 18, color: '#44E183' }}>
                            arrow_upward
                        </i>
                    )}

                    {props.trend === false && (
                        <i className="material-icons arrow-icon" style={{ fontSize: 18, color: '#F14B44' }}>
                            arrow_downward
                        </i>
                    )}
                </div>
            )}
            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    .card {
                        width: 220px;
                        padding: 15px 20px;
                        border-radius: 4px;
                    }
                    .card-green {
                        background: rgba(68, 225, 131, 0.1);
                        color: #44e183;
                        box-shadow: inset 0px -2px 0px #44e183;
                    }
                    .card-red {
                        background: rgba(236, 64, 74, 0.1);
                        color: #f14b44;
                        box-shadow: inset 0px -2px 0px #f14b44;
                    }
                    .title {
                        color: #97a2b4;
                        margin-bottom: 5px;
                    }
                    .value {
                        font-size: 22px;
                        font-weight: 500;
                    }
                    .submarketInsights,
                    .multiFamilyInsights {
                        background: #f5f6f9;
                        color: #161c26;
                        width: 250px;

                        .title {
                            color: #687893;
                        }
                    }
                    .basicMultiFamilyInsights {
                        box-shadow: initial;
                    }
                `}
            </style>
        </div>
    )
}
