import React from 'react'
import checkIcon from './check-icon.svg'

type Props = {
    index: number
    name: string
    changeCheckedItems: any
    checked: boolean
    readOnly?: boolean
}

export const CheckboxItemForSelect = (props: Props) => {
    const { index, name, changeCheckedItems, checked } = props
    return (
        <div className={'checkbox-item'}>
            <span
                className={'checkbox'}
                onClick={() => {
                    if (!props.readOnly) changeCheckedItems(index, !checked)
                }}
            >
                {checked && <img src={checkIcon} />}
            </span>
            <span className={'checkbox-text'}>{name.search(/^t\d+$/i) >= 0 ? 'Annualized ' + name : name}</span>
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .checkbox-item {
                    margin-bottom: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    .checkbox {
                        width: 18px;
                        height: 18px;
                        background: ${checked ? '#4486FF' : '#fff'};
                        border-width: 1px;
                        border-style: solid;
                        border-color: ${checked ? '#4486FF' : '#aab1bd'};
                        box-sizing: border-box;
                        border-radius: 3px;
                        user-select: none;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            margin: 0;
                        }
                    }
                    .checkbox-text {
                        margin-left: 10px;
                        font-size: 14px;
                        color: $black;
                        text-transform: capitalize;
                    }
                }
            `}</style>
        </div>
    )
}
