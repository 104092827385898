import { useEffect } from 'react'

export const useSyncScroll = (firstID: string, secondId: string): void => {
    useEffect(() => {
        const handleScroll = (e) => {
            const sourceElem = document?.getElementById(firstID)
            const targetElem = document?.getElementById(secondId)

            if (!sourceElem || !targetElem) return

            targetElem.scrollLeft = sourceElem.scrollLeft
        }

        const handleScroll1 = (e) => {
            const sourceElem = document?.getElementById(secondId)
            const targetElem = document?.getElementById(firstID)

            if (!sourceElem || !targetElem) return

            targetElem.scrollLeft = sourceElem.scrollLeft
        }

        const wrapper = document?.getElementById(firstID)
        wrapper?.addEventListener('scroll', handleScroll, false)

        const wrapper1 = document?.getElementById(secondId)
        wrapper1?.addEventListener('scroll', handleScroll1, false)
        return () => {
            wrapper?.removeEventListener('scroll', handleScroll)
            wrapper1?.removeEventListener('scroll', handleScroll1)
        }
    }, [firstID, secondId])
}

export const useSyncScrollByClassName = (firstID: string, secondId: string): void => {
    useEffect(() => {
        return syncScrollByClassName(firstID, secondId)
    }, [firstID, secondId])
}

export const syncScrollByClassName = (firstID: string, secondId: string) => {
    const handleScroll = (e) => {
        const sourceElem = document?.getElementsByClassName(firstID)[0]
        const targetElem = document?.getElementsByClassName(secondId)[0]

        if (!sourceElem || !targetElem) return

        setTimeout(() => {
            targetElem.scrollLeft = sourceElem.scrollLeft
        }, 1000)
    }

    const handleScroll1 = (e) => {
        const sourceElem = document?.getElementsByClassName(firstID)[0]
        const targetElem = document?.getElementsByClassName(secondId)[0]

        if (!sourceElem || !targetElem) return

        setTimeout(() => {
            targetElem.scrollLeft = sourceElem.scrollLeft
        }, 1000)
    }

    const wrapper = document?.getElementsByClassName(firstID)[0]
    wrapper?.addEventListener('scroll', handleScroll, false)

    const wrapper1 = document?.getElementsByClassName(secondId)[0]
    wrapper1?.addEventListener('scroll', handleScroll1, false)
    return () => {
        wrapper?.removeEventListener('scroll', handleScroll)
        wrapper1?.removeEventListener('scroll', handleScroll1)
    }
}
