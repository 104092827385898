import React from 'react'

type Props = {
    tableHeader: []
    tableBody: []
}

export const InsightsTable = ({ tableHeader, tableBody }) => {
    return (
        <>
            <table className={`table table-striped insight-table`}>
                <thead>
                    {tableHeader.map((headRow, ind) => (
                        <tr key={ind}>
                            {headRow.cells.map((cell, i) => (
                                <th
                                    key={i}
                                    className={`text-${cell.align} ${cell.bordering ? 'border-right' : ''}`}
                                    dangerouslySetInnerHTML={{ __html: cell.content }}
                                    colSpan={cell.colspan || 1}
                                    style={{
                                        ...cell.customStyles,
                                    }}
                                ></th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {tableBody.map((bodyRow, ind) => (
                        <tr key={ind}>
                            {bodyRow.cells.map((cell, ind) => (
                                <td
                                    key={ind}
                                    className={`text-${cell.align}  ${cell.bordering ? 'border-right' : ''} ${
                                        cell.bold ? 'cell-bold' : ''
                                    }`}
                                    style={{ width: cell.width || 'initial' }}
                                    dangerouslySetInnerHTML={{ __html: cell.content }}
                                    colSpan={cell.colspan || 1}
                                ></td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>

            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    .table {
                        color: $black;
                        > thead {
                            > tr {
                                > th {
                                    font-weight: 400;
                                    font-size: 0.76rem;
                                    line-height: 1.2em;
                                    text-transform: uppercase;
                                    color: $gray-nevada;
                                    vertical-align: bottom;
                                    padding: 0.3rem 0.8rem;
                                    border-top: none;
                                    border-bottom: none;
                                }
                            }
                        }
                        > tbody {
                            > tr {
                                &:nth-of-type(odd) {
                                    background-color: $background;
                                }
                                > td {
                                    font-size: 0.9rem;
                                    line-height: 1.3em;
                                    padding: 0.4rem 0.8rem;
                                    border-top: none;
                                    &.cell-bold {
                                        font-weight: 500;
                                    }
                                }
                            }
                        }
                        .border-right {
                            border-right: 1px solid $border;
                        }
                    }
                `}
            </style>
        </>
    )
}
