import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../store'

type Props = {
    linkAnchor?: string
}

export const DashboardComparablesTab = (props: Props) => {
    const { linkAnchor } = props
    const project = useSelector((store: ReduxStoreState) => store.lenderProjects.project)

    const projectId = useMemo(() => {
        return project?.id
    }, [project])

    const [iframeHeight, setIframeHeight] = useState(650)
    const setPageHeight = useCallback(() => {
        const iframe = document.getElementById(`iframe-${linkAnchor}`)
        let pageHeight = 0
        setIframeHeight(pageHeight)

        setTimeout(() => {
            const findHighestNode = (nodesList) => {
                for (let i = nodesList.length - 1; i >= 0; i--) {
                    if (nodesList[i].scrollHeight && nodesList[i].clientHeight) {
                        const elHeight = Math.max(nodesList[i].scrollHeight, nodesList[i].clientHeight)
                        pageHeight = Math.max(elHeight, pageHeight)
                    }
                    if (nodesList[i].childNodes.length) findHighestNode(nodesList[i].childNodes)
                }
            }

            // @ts-ignore
            findHighestNode(iframe.contentWindow.document.documentElement.childNodes)
            //console.log('pageHeight', pageHeight)

            setIframeHeight(pageHeight)
        }, 10)
    }, [linkAnchor])

    useEffect(() => {
        setPageHeight()
    }, [setPageHeight])

    if (!projectId) {
        return null
    }

    return (
        <div className="col-md-12 my-4">
            {linkAnchor == 'rent' && (
                <iframe
                    id={'iframe-rent'}
                    src={`/expose/${projectId}/comparables-rent-iframe`}
                    frameBorder="0"
                    width={'100%'}
                    height={iframeHeight}
                    // key={linkAnchor + iframeHeight}
                    onLoad={() => {
                        setPageHeight()
                    }}
                />
            )}
            {linkAnchor == 'sales' && (
                <iframe
                    id={'iframe-sales'}
                    src={`/expose/${projectId}/comparables-sales-iframe`}
                    frameBorder="0"
                    width={'100%'}
                    height={iframeHeight}
                    // key={linkAnchor + iframeHeight}
                    onLoad={() => {
                        setPageHeight()
                    }}
                />
            )}
            {/*language=scss*/}
            <style jsx global>{`
                iframe {
                    //height: calc(100vh - 240px);
                    overflow-x: hidden;
                    -ms-overflow-style: none; /* IE and Edge */
                    scrollbar-width: none; /* Firefox */
                }
                iframe::-webkit-scrollbar {
                    width: 0 !important;
                    height: 0 !important;
                    display: none;
                }
                iframe::-webkit-scrollbar-thumb {
                    background: red;
                }
            `}</style>
        </div>
    )
}
