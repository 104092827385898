const cloneDeep = require('lodash.clonedeep')

export function mergeCashFlow(cashFlow, mergedCashFlow: any, merger: (originalRow, mergedRow) => void) {
    const reduxCashFlow = cloneDeep(cashFlow)
    //console.log(mergedCashFlow)
    for (const prop in reduxCashFlow) {
        if (!reduxCashFlow[prop]?.length) {
            if (mergedCashFlow[prop]) {
                merger(reduxCashFlow[prop], mergedCashFlow[prop])
            }
        } else {
            if (mergedCashFlow[prop] && mergedCashFlow[prop]?.length)
                reduxCashFlow[prop].forEach((row) => {
                    mergedCashFlow[prop].forEach((mergedRow) => {
                        if (row?.id === mergedRow?.id || (row?.id == 'retailnoi' && mergedRow?.id == 'retailIncome')) {
                            merger(row, mergedRow)
                        }
                        if (row.subRows?.length) {
                            row.subRows?.forEach((subRow) => {
                                mergedRow.subRows?.some((mergedSubRow) => {
                                    if (subRow.title === mergedSubRow?.title) {
                                        merger(subRow, mergedSubRow)
                                    }
                                })
                            })
                        }
                    })
                })
        }
    }
    return reduxCashFlow
}
