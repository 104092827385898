import React, { CSSProperties, FunctionComponent } from 'react'

export enum ButtonStyle {
    'regular',
    'ghost',
    'transparent',
}

export type FooterButton = {
    title: string
    type: ButtonStyle
    callback: (any) => void
    style?: CSSProperties
    disabled?: boolean
}

type Props = {
    buttons?: Array<FooterButton>
    width?: string | number
}

export const FixedFooter: FunctionComponent<Props> = (props: Props) => {
    const { buttons, width } = props
    return (
        <div className="confirmation-footer">
            {buttons.map((button: FooterButton, i) => (
                <button
                    className={ButtonStyle[button.type]}
                    key={i}
                    onClick={button.callback}
                    style={button.style ? button.style : {}}
                    disabled={button?.disabled || false}
                >
                    {button.title}
                </button>
            ))}
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .confirmation-footer {
                    width: ${width ? width : '100%'};
                    position: fixed;
                    bottom: 0;
                    right: 0;
                    background-color: #fff;
                    border-top: 1px solid #d9e0e5;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    padding: 27px;
                    box-shadow: 0px -5px 10px -10px $gray-nevada;
                    z-index: 1000;

                    button {
                        border-radius: 3px;
                        background-color: #fff;
                        border: 1px solid #4486ff;
                        margin: 0 10px;
                        min-width: 160px;
                        font-size: 13px;
                        font-weight: 500;
                        &.regular {
                            background: #4486ff;
                            color: #fff;
                        }
                        &.ghost {
                            color: #4486ff;
                        }
                        &.transparent {
                            color: $gray;
                            border-color: transparent;
                        }
                    }
                    button[disabled] {
                        opacity: 0.5;
                    }
                }
            `}</style>
        </div>
    )
}
