import { Spinner } from './controls/Spinner'

export const LoadingOverlay = () => {
    return (
        <div className="screen-overlay">
            <Spinner />
            <style jsx>{`
                :global(body) {
                    overflow-y: hidden;
                }
                .screen-overlay {
                    width: 100vw;
                    height: 100vh;
                    position: fixed;
                    background-color: #00000030;
                    z-index: 999999;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            `}</style>
        </div>
    )
}
