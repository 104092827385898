export const isFormModified = (
    visitedFields: any,
    modifiedFields: any,
    formWasSubmitted?: boolean,
    dirtyFieldsSinceLastSubmit?: any,
) => {
    let isModified = false

    for (let key in visitedFields) {
        if (
            visitedFields.hasOwnProperty(key) &&
            visitedFields[key] &&
            modifiedFields.hasOwnProperty(key) &&
            modifiedFields[key]
        ) {
            if (!formWasSubmitted || (formWasSubmitted && dirtyFieldsSinceLastSubmit[key])) {
                isModified = true
                break
            }
        }
    }

    return isModified
}
