import React, { CSSProperties, PropsWithChildren } from 'react'

type Props = {
    className?: string
    onClick: () => void
    style?: CSSProperties
}

export function GreenButton(props: PropsWithChildren<Props>) {
    return (
        <>
            <button className={props.className} onClick={props.onClick} style={props.style}>
                {props.children}
            </button>
            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                button {
                    color: $white;
                    background-color: $active-button-color;
                    height: 36px;
                    padding: 0 18.5px;
                    border-radius: 3px;
                    font-weight: 500;
                    font-size: 13px;
                    outline: none;
                    border-width: 0;
                }
            `}</style>
        </>
    )
}
