import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../store'
import { getMetrics } from 'origination-model'

export function useMetrics() {
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const cashFlow = useSelector((state: ReduxStoreState) => state.lender.originationModel?.cashFlow)
    const unitMix = useSelector((state: ReduxStoreState) => state.lender.unitMix)

    return getMetrics({ model, cashFlow, unitMix })
}
